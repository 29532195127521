import React from 'react'
import { UserAuth } from '../context/AuthContext';
import Reactlogo from './Logo.svg'



const Account = () => {
    const { user, logout } = UserAuth();

}

const Navbar = () => {
    const { user, logout } = UserAuth();
    
  return (
    <div>
<nav class=" border-gray-200 px-2 sm:px-4 py-2.5  bg-gray-900">
  <div class="sm:flex sm:items-center sm:justify-between ">
    <a href="" class="flex items-center">
        <img src={Reactlogo} class="mr-2 h-6 sm:h-12" alt="Logo"/>
        <span class="self-center text-xl font-semibold whitespace-nowrap text-white">Dashlink</span>
    </a>
    <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
      <span class="sr-only">Open main menu</span>
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
    </button>
    <div class="hidden w-full md:block md:w-auto" id="navbar-default">
      <ul class="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
        <li>
          <a href="#" class="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white" aria-current="page"></a>
        </li>
        <li>
          <a href="#" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"></a>
        </li>
        <li>
          <a href="#" class="block py-2 pr-4 pl-3  md:p-0 text-gray-300 md:hover:text-white hover:bg-gray-700 hover:text-white md:hover:bg-transparent border-gray-700">Deutsch</a>
        </li>
        <li>
          <a href="#" class=" text-gray-100 bg-indigo-600 hover:bg-indigo-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Link erstellen</a>
        </li>
        <div>
          <button  class="block py-2 pr-4 pl-3  md:p-0 text-gray-300 md:hover:text-white hover:bg-gray-700 hover:text-white md:hover:bg-transparent border-gray-700">{user && user.email}</button>
        </div>
      </ul>
    </div>
  </div>
</nav>

    </div>
  )
}

export default Navbar