import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import Sidebar from './Sidebar';





const Account = () => {
    return (
      <div className=' bg-slate-200'>

    <script async="async" data-cfasync="false" src="//upgulpinon.com/1?z=5286550"></script>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <Footer></Footer>
  </div>
  );


};

export default Account;