import React from 'react'
import Reactlogo from './Logo.svg'

const Footer = () => {
  return (
    <div>

<footer class="p-4  shadow md:px-6 md:py-8 bg-gray-900">
    <div class="sm:flex sm:items-center sm:justify-between">
        <a href="https://flowbite.com/" class="flex items-center mb-4 sm:mb-0">
            <img src={Reactlogo} class="mr-2 h-12" alt="Logo"/>
            <span class="self-center text-2xl font-semibold whitespace-nowrap text-white">Dashlink</span>
        </a>
        <ul class="flex flex-wrap items-center mb-6 text-sm  sm:mb-0 text-gray-400">
            <li>
                <a href="#" class="mr-4 hover:underline md:mr-6 ">Impressum</a>
            </li>
            <li>
                <a href="#" class="mr-4 hover:underline md:mr-6">Datenschutzerklärung</a>
            </li>
            <li>
                <a href="#" class="mr-4 hover:underline md:mr-6 ">Teilnahmebedingungen</a>
            </li>
            <li>
                <a href="#" class="hover:underline">AGB</a>
            </li>
        </ul>
    </div>
    <hr class="my-6 sm:mx-auto border-gray-700 lg:my-8"/>
    <span class="block text-sm  sm:text-center text-gray-400">© 2022 <a href="https://flowbite.com/" class="hover:underline">Dashlink</a>. All Rights Reserved.
    </span>
</footer>



    </div>
  )
}

export default Footer
