import React from 'react';
import Signin from './components/Signin';
import { Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './components/Dashboard';


function App() {
  return (
  <div>
       <div>
      <AuthContextProvider>
        <Routes>
          <Route path='/' element={<Signin />} />
          
          <Route
            path='/dashboard'
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
            />
        </Routes>
      </AuthContextProvider>
    </div>
  </div>

  );
}

export default App;