import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import Footer from './Footer';
import Navbar1 from './Navbar1';

const Signin = () => {
  return (
    <div>
      <Navbar1></Navbar1>
      <div className='bg-slate-200 h-screen'>

      </div>
      <div>

      <Footer></Footer>
      </div>
    

  </div>
  );
};

export default Signin;