import Reactlogo from './Logo.svg'
import  { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Transition } from "@headlessui/react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  };
  




const Navbar1 = () => {
    const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('')
  const { createUser } = UserAuth();
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const { signIn } = UserAuth();

  

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await createUser(email, password);
      navigate('/dashboard')
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    setError('')
    try {
      await signIn(email, password)
      navigate('/dashboard')
    } catch (e) {
      setError(e.message)
      console.log(e.message)
    }
  };


    
  return (
    <div>
<nav class=" border-gray-200 px-2 sm:px-4 py-2.5  bg-gray-900">
  <div class="sm:flex sm:items-center sm:justify-between ">
    <a href="" class="flex items-center">
        <img src={Reactlogo} class="mr-2 h-6 sm:h-12" alt="Logo"/>
        <span class="self-center text-xl font-semibold whitespace-nowrap text-white">Dashlink</span>
    </a>
    <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
      <span class="sr-only">Open main menu</span>
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
    </button>
    <div class="hidden w-full md:block md:w-auto" id="navbar-default">
    <ul class="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
        <li>
          <a href="#" class="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white" aria-current="page"></a>
        </li>
        <li>
          <a href="#" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"></a>
        </li>
       
          <button  class=" text-gray-300 md:hover:text-white hover:bg-gray-700 hover:text-white md:hover:bg-transparent border-gray-700">Deutsch</button>
       
        
          <button onClick={handleOpen1}  class="  text-gray-300 md:hover:text-white hover:bg-gray-700 hover:text-white md:hover:bg-transparent border-gray-700">Anmelden</button>
          <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          
          <div className='max-w-[1000x] mx-auto my-auto '>
      <div>
        <h1 className='text-2xl font-bold py-2'>Anmelden</h1>
        <p className='py-2'>
          Du hast kein Account?{' '}
          <Link to='/signup' className='underline'>
            Registrier dich!
          </Link>
        </p>
      </div>
      <form onSubmit={handleSubmit2}>
        <div className='flex flex-col py-2'>
          <label className='py-2 font-medium'>Email</label>
          <input onChange={(e) => setEmail(e.target.value)} className='border p-3 rounded-lg' type='email' />
        </div>
        <div className='flex flex-col py-2'>
          <label className='py-2 font-medium'>Password</label>
          <input onChange={(e) => setPassword(e.target.value)} className='border p-3 rounded-lg' type='password' />
        </div>
        <div className='flex flex-row-reverse'>
        <button className='text-gray-200 bg-indigo-600 hover:bg-indigo-600 hover:text-white px-3 py-2 rounded-md text-lg font-semibold my-4'>
          Anmelden
        </button>
        </div>
      </form>
    </div>
  

          </Typography>
        </Box>
      </Modal>
        
        <li>
          <button onClick={handleOpen} class=" text-gray-100 bg-indigo-600 hover:bg-indigo-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Regestrieren</button>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          
          <div className='max-w-[1000x] mx-auto my-auto '>
      <div>
        <div className='flex flex-row'>
        <h1 className='text-2xl font-bold p-2'>Account Regestrieren</h1>
        </div>
        <p className='p-2'>
          Du hast schon ein Account?{' '}
          <Link to='/' className='underline'>
            Meld dich an!
          </Link>
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='flex flex-col py-2'>
          <label className='py-2 font-medium'>Email</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className='border p-3 rounded-lg'
            type='email'
          />
        </div>
        <div className='flex flex-col py-2'>
          <label className='py-2 font-medium'>Password</label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            className='border p-3 rounded-lg'
            type='password'
            />
        </div>
        <div className='flex flex-row-reverse'>
        <button className="text-gray-200 bg-indigo-600 hover:bg-indigo-600 hover:text-white px-3 py-2 rounded-md text-lg font-semibold my-4">
          Regestrieren
        </button>
        </div>
      </form>
    </div>

          </Typography>
        </Box>
      </Modal>
        </li>
      </ul>
    </div>
  </div>
</nav>

    </div>
  )
}

export default Navbar1